import React, { useState, useEffect } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import {
  signupUser,
  selectSignUpMessage,
  setError,
  clearErrors,
} from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CustomButton from "components/CustomButtons/Button.js";
import logo from "../../assets/img/logo.png";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Image from "./background.png"; // Import using relative path

const useStyles = makeStyles((theme) => ({
  successText: {
    color: "#222222",
    fontSize: "20px",
  },
  cardImage: {
    backgroundImage: 'url(require("./background.png"))',
  },
  formInput: {
    backgroundColor: "#e7f0f4",
  },

}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Register(props) {
  const classes = useStyles();
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [phone, setPhone] = useState(""); // AGREGADO
  const [firstname, setFirstName] = useState(""); // AGREGADO
  const [lastname, setLastName] = useState(""); // AGREGADO
  const [password, setPassword] = useState("");
  const [city, setCity] = useState(""); // AGREGADO
  const [country, setCountry] = useState(""); // AGREGADO
  const [cp, setCp] = useState(""); // AGREGADO
  const [termsAccepted, setTermsAccepted] = useState(false);

  // const [error, setError] = useState("");
  const dispatch = useDispatch();
  const error = useSelector(selectSignUpMessage);
  const history = useHistory();

  const submitForm = () => {
    if (email === "" || password === "" || username === "") {
      dispatch(setError({ message: "Campos obligatorios", severity: "error" }));
      return;
    }
    if (email != emailVal) {
      dispatch(
        setError({ message: "El correo electrónico debe ser el mismo", severity: "error" })
      );
      return;
    }
    if (!termsAccepted) {
      dispatch(
        setError({
          message: "Debes aceptar los términos y condiciones",
          severity: "error",
        })
      );
      return;
    }
    dispatch(
      //signupUser({ email: email, password: password, username: username })

      //signupUser({ email: email, password: password, username: username, firstname: firstname = "name", lastname: lastname = "ln", phone: phone = 1234, city: city = "", country: country = "", cp: cp = 4321 })
      
      signupUser({ email: email, password: password, username: username, firstname: firstname, lastname: lastname, phone: phone, city: city, country: country, cp: cp })
    );

    //history.push("/login");
  };

  useEffect(() => {
    if (error.message == "Inscripción exitosa!") {
      setTimeout(() => {
        dispatch(clearErrors());
        history.push("/login");
      }, 3000);
    }
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              paddingBottom: "150px",
              paddingTop: "100px",
            }}
          >
            <CardBody
              style={{
                paddingRight: "15%",
                paddingLeft: "15%",
              }}
            >
              <img src={logo} alt="logo" width={50} className="center" />
              <h2 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h2>

              &nbsp;
              <div className="center">
                <div className="">
                  <form>
                    <Typography variant="h5" style={{ marginBottom: 8 }}>
                      Registro de usuarios
                    </Typography>
                    {/* AGREGADO */}
                    &nbsp;
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Apellido"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {/* FIN AGREGADO */}
                    &nbsp;
                    <TextField
                      label="Usuario"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Repetir Email"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={emailVal}
                      onChange={(e) => setEmailVal(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Contraseña"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      type="password"
                      value={password}
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* AGREGADO */}
                    &nbsp;
                    <TextField
                      label="Celular"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      type="number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Ciudad"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="País"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Código Postal"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={cp}
                      onChange={(e) => setCp(e.target.value)}
                    />
                    {/* FIN AGREGADO */}
                    &nbsp;
                    <Paper elevation={0}>
                      <Checkbox
                        label="Terminos y condiciones"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <p>
                        <span> Términos y condiciones</span>{" "}
                      </p>
                    </Paper>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="form-input"
                      size="large"
                      onClick={submitForm}
                    >
                      Registrate
                    </Button>
                    {error.message && (
                      <Snackbar open={error.message} autoHideDuration={3000}>
                        <Alert severity={error.severity} onClick={() => setError(null)}>
                          {props.error || error.message}
                        </Alert>
                      </Snackbar>
                    )}
                    &nbsp;
                  </form>
                  <img src={logo} alt="logo" className="logo" />
                  <h4 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h4>
                  <div className="center">
                    <CustomButton onClick={() => history.push("/login")}>Ingresar</CustomButton>
                    &nbsp;
                    <CustomButton onClick={() => history.push("/home")}>Inicio</CustomButton>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Register.propTypes = {
  login: PropTypes.func,
  error: PropTypes.bool,
  setIsAuthenticated: PropTypes.func,
};
