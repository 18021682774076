import React from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";

import logo from "../../assets/img/logo.png";
// import { bugs, website, server } from "variables/general.js";
import Image from "./background.png"; // Import using relative path

import { text } from "./../Text/text";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart,
// } from "variables/charts.js";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles((theme) => ({
  successText: {
    color: "#222222", // "#4caf50",
    fontSize: "20px",
  },
  cardImage: {
    backgroundImage: 'url(require("./background.png"))',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  //const count = useSelector(selectCount);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              paddingBottom: "150px",
              paddingTop: "100px",
            }}
          >
            <CardBody
              style={{
                paddingRight: "15%",
                paddingLeft: "15%",
              }}
            >
              <img src={logo} alt="logo" width={50} className="center" />
              {/* <h2 style={{ textAlignVertical: "center", textAlign: "center", fontSize: "1.5em"}} >Servicio Para Observadores/as Territoriales</h2> */}
              <h2 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h2>
              <p>
                <span className={classes.successText}>
                  {text.about.welcome}
                </span>
              </p>
              <p>
                <span className={classes.successText}>
                  {text.about.iniciativa}
                </span>
              </p>
              <p>
                <Button color="primary" onClick={() => history.push("/home/readmore")}>Leer más</Button>
              </p>
              &nbsp;
              <GridItem xs={12} sm={6} md={12}>
                <Button onClick={() => history.push("/register")}>Registrate</Button>
                &nbsp;
                <Button onClick={() => history.push("/login")}>Ingresar</Button>
                &nbsp;
                <Button onClick={() => history.push("/recover")}>Recuperar contraseña</Button>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
