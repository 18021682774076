import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    title: "",
    description: "",
    id: null,
};
import { useSelector } from 'react-redux';

export const counterSlice = createSlice({
    name: 'story',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateTitle: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.title = action.payload;
        },
        updateDescription: (state, action) => {
            state.description = action.payload;
        },
        updateId: (state, action) => {
            state.id = action.payload;
        },
        reset: (state) => initialState,
        editStory: (state, action) => {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.id = action.payload.id;
        },
        deleteStory: (state, action) => {
            state.title = "borrada";
            state.description = "borrada";
            state.id = action.payload.id;
        }
    }

});

export const { updateTitle, updateDescription, updateId, reset, editStory, deleteStory } = counterSlice.actions;

export const stateTitle = (state) => state.story.title;
export const stateDescription = (state) => state.story.description;
export const stateUserID = (state) => state.login.user.id;
export const stateID = (state) => state.story.id;
export const stateUserToken = (state) => state.login.token;


export default counterSlice.reducer;
