import { server } from "../../config";

function signupUser_api(user) {
  return fetch(server + "/user/signup", {
    headers: { "Content-Type": "application/json" },
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify({
      username: user.username,
      password: user.password,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      city: user.city,
      country: user.country,
      cp: user.cp
    }),
  });
}

function login_api(user) {
  console.log("login_api");
  return fetch(server + "/user/login", {
    headers: { "Content-Type": "application/json" },
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify({
      username: user.username,
      password: user.password,
      email: user.email,      
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      city: user.city,
      country: user.country,
      cp: user.cp
    }),
  });
}

function recoverPassword_api(user) {
  console.log("API: " + user.email); // BORRAR
  return fetch(server + "/user/recoverpassword", {
    headers: { "Content-Type": "application/json" },
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify({
      email: user.email
    }),
  });
}

export { signupUser_api, login_api, recoverPassword_api };
