import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  viewPort: {
    // Vista del mapa
    longitude: -65.5021865,//-62.228306963714076
    latitude: -43.3108835,//-36.981645119763485
    zoom: 5,
  },
};

export const storieSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateViewport: (state, action) => {
      state.viewPort = action.payload;
    },
  },
  extraReducers(builder) {
  }
});


export const {
  updateViewport,
  centerViewPort,
} = storieSlice.actions;

export const state_data = (state) => state.map.data;
export const state_viewPort = (state) => state.map.viewPort;

export default storieSlice.reducer;
