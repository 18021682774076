let text = {
  about: {
    //welcome: "Bienvenidos/as a la plataforma SPOT - Servicio Para el Ordenamiento Territorial.",
    welcome: "Bienvenidos/as a la plataforma SPOT - Servicio Para Observadores/as Territoriales",
    iniciativa: "Mediante esta iniciativa apuntamos a generar herramientas para el desarrollo territorial. Con tu colaboración podemos generar información valiosa para el manejo de la tierra y el agua. Vos podés ser un o una Observador/a Territorial y contribuir con datos acerca de los usos y coberturas del suelo y nosotros/as usaremos esos datos y tecnologías como la inteligencia artificial y el procesamiento de imágenes satelitales de acceso gratuito para generar series de mapas anuales.",
    segundaEtapa: "En esta segunda etapa y con la colaboración de Observadores/as Territoriales queremos transformar esta tecnología en una herramienta permanente para el mapeo del territorio que nos permita identificar tendencias temporales de cambio de uso y cobertura del suelo, estimar usos del agua y generar posibles trayectorias de cambio hacia el futuro, entre otras cosas.",

  },
  about2: {
  },
};

module.exports = { text };
