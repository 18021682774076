
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
//import Admin from "layouts/Admin.js";
//import RTL from "layouts/RTL.js";
import { Provider } from "react-redux";
import { store } from "./app/store";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import CircularProgress from '@material-ui/core/CircularProgress';

import "assets/css/material-dashboard-react.css?v=1.10.0";
import { lazy, Suspense } from "react";
import { useSelector } from 'react-redux';
import {selectAuthUser} from '../src/views/Login/reducer';
import LoginPage from "views/Login/Login";
import RegisterPage from 'views/Login/Register';
import RecoverPage from 'views/Login/Recover';
import PublicHome from 'layouts/Public';


function Home(){
  return <h5> HOME </h5>
}



function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      component={
        ({ location }) => (
          !isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/admin',
                state: { from: location }
              }}
            />
          ))
      }
    />
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
};


function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      component={
        ({ location }) => (
          isAuthenticated
            ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            ))
      }
    />
  );
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
};

const protected_routes = [
  {
    path: 'admin',
    component: lazy(() => import("layouts/Admin.js")),
    exact: false
  },
];


const public_routes = [
  {
    path: 'home',
    component: lazy(() => import("layouts/Public.js")),
    exact: false
  },
];

const PublicRoutes = () => (
  <Switch>
    <Suspense
      fallback={<CircularProgress />}
    >
      {public_routes.map(({ component: Component, path, exact }) => (
        <Route
          path={`/${path}`}
          key={path}
          exact={exact}
        >
          <Component />
        </Route>
      ))}
     
    </Suspense>
  </Switch>
);


const ProtectedRoutes = () => (
  <Switch>
    <Suspense
      fallback={<CircularProgress />}
    >
      {protected_routes.map(({ component: Component, path, exact }) => (
        <Route
          path={`/${path}`}
          key={path}
          exact={exact}
        >
          <Component />
        </Route>
      ))}
     
    </Suspense>
  </Switch>
);

function RenderDOM(){

const isAuthenticated = useSelector(selectAuthUser);

  return(
<BrowserRouter>
    
    <Suspense fallback={<CircularProgress />}>
      <Switch>
      
      <PublicRoute
            path="/login"
            isAuthenticated={isAuthenticated}
          >
            <LoginPage  />
      </PublicRoute>
      <PublicRoute
            path="/register"
            isAuthenticated={isAuthenticated}
          >
            <RegisterPage  />
      </PublicRoute>
      <PublicRoute
            path="/recover"
            isAuthenticated={isAuthenticated}
          >
            <RecoverPage  />
      </PublicRoute>
      <PublicRoute
            path="/home"
            isAuthenticated={isAuthenticated}
          >
            <PublicRoutes  />
      </PublicRoute>
      <PrivateRoute
            path="/admin"
            isAuthenticated={isAuthenticated}
          >
            <ProtectedRoutes />
      </PrivateRoute>
      <Route
      component={({ location })=><Redirect
        to={{
          pathname: '/home',
          state: { from: location }
        }}
      />}
    />
      
      </Switch>
      </Suspense>
   
    
  </BrowserRouter>)
}
ReactDOM.render(
  <Provider store={store}>
  <RenderDOM />
  </Provider>
  ,
  document.getElementById("root")
);
