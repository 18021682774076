import { configureStore } from "@reduxjs/toolkit";
import storyReducer from "../views/CreateStory/reducer";
import logger from 'redux-logger';
import loginReducer from "../views/Login/reducer";
import mapReducer from "../views/Map/reducer"
import storiesReducer from "../views/StoriesDashboard/reducer";
import publicData from "../views/PublicView/reducer";
import thunk from 'redux-thunk'

export const store = configureStore({
  reducer: {
    story: storyReducer,
    login: loginReducer,
    map: mapReducer,
    stories: storiesReducer,
    public: publicData
  },
  middleware:[logger, thunk]
});
