import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import Image from "./background.png"; // Import using relative path

import { text } from "./../Text/text";

const useStyles = makeStyles((theme) => ({
  successText: {
    color: "#222222",
    fontSize: "20px",
  },
  cardImage: {
    backgroundImage: 'url(require("./background.png"))',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  //const count = useSelector(selectCount);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              paddingBottom: "150px",
              paddingTop: "100px",
            }}
          >
            <CardBody
              style={{
                paddingRight: "15%",
                paddingLeft: "15%",
              }}
            >
              <img src={logo} alt="logo" width={50} className="center" />
              <h2 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h2>

              <p>
                <span className={classes.successText}>
                  {text.about.welcome}
                </span>
              </p>
              <p>
                <span className={classes.successText}>
                  {text.about.iniciativa}
                </span>
              </p>
              <p>
                <span className={classes.successText}>
                  Esta plataforma es una adaptación de “Agrohistorias”, un servicio web elaborado por el Dr. Agustín García Pereyra (Insight Centre for Data Analytics, National University of Ireland, Galway) y está enmarcado en el proyecto “Automatización del mapeo del uso y la cobertura del suelo mediante imágenes satelitales y ciencia ciudadana en un valle semiárido de la Patagonia Argentina”. Este proyecto es financiado por The National Geographic Society y es llevado a cabo en conjunto por profesionales del CONICET, INTA y la UNPSJB. En el año 2018 empezamos a trabajar en equipo para generar la tecnología necesaria para poder elaborar mapas de la zona agrícola del Valle Inferior del Río Chubut. Desde entonces hemos diseñado un modelo de inteligencia artificial especialmente adaptado a nuestras necesidades <a href="https://doi.org/10.1016/j.rsase.2022.100703" target="_blank">(Trujillo et al 2022)</a> y hemos generado un mapa para el año 2019 y el año 2020.
                </span>
              </p>
              <p>
                <span className={classes.successText}>
                  {text.about.segundaEtapa}
                </span>
              </p>
              <p>
                <span className={classes.successText}>
                  Si querés más información sobre nuestro trabajo explorá la página <a href="https://unriotodaslasaguas.com.ar" target="_blank">www.unriotodaslasaguas.com.ar</a>
                </span>
              </p>
              <p>
                <Button color="primary" onClick={() => history.push("/home")}>Volver</Button>
              </p>
              &nbsp;
              <GridItem xs={12} sm={6} md={12}>
                <Button onClick={() => history.push("/register")}>Registrate</Button>
                &nbsp;
                <Button onClick={() => history.push("/login")}>Ingresar</Button>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
