import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {server} from '../../config';

const initialState = {
    stories: [],
};

export const getStories = createAsyncThunk('stories/getStories', async (payload, { getState }) => {
    
    const state = getState()
    const url = server + "/map/stories/"+state.login.user.id;
    const response = await fetch(url, {
        headers: { 'Content-Type': "application/json" },
        method: 'GET', // *GET, POST, PUT, DELETE, etc.             
        // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body data type must match "Content-Type" header
      })
    return response.json()
  })


export const storiesSlice = createSlice({
    name: 'stories',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers(builder) {
        // omit posts loading reducers
        builder.addCase(getStories.fulfilled, (state, action) => {
            state.stories=action.payload
        }),
        builder.addCase(getStories.rejected, (state, action) => {
           console.log("error, promise rejected")
        })
      }

});


export const selectStories = (state) => state.stories.stories;

export default storiesSlice.reducer;
