/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import CreateStory from "@material-ui/icons/Create";
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// // core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
// import UserProfile from "views/UserProfile/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// //import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// // core components/views for RTL layout
// import CreateStoryPage from "./views/CreateStory/CreateStory";
import PublicStories from "./views/PublicView"
import Inicio from "./views/Home";
import ReadMore from "./views/ReadMore";


const dashboardRoutes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: CreateStory,
    component: Inicio,
    layout: "/home",
  }, {
    path: "/publicstories",
    name: "Observaciones",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: PublicStories,
    layout: "/home",
  },
  {
    path: "/readmore",
    name: "Leer Más",
    icon: CreateStory,
    component: ReadMore,
    layout: "/home",
  },
];

export default dashboardRoutes;
