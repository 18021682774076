import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
// react plugin for creating charts
// import ChartistGraph from "react-chartist";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// import { green, orange, blue } from '@material-ui/core/colors';
// import Icon from "@material-ui/core/Icon";
// // @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';
// import Danger from "components/Typography/Danger.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";

import rasterVacio from "./vacio.png"; // Import using relative path
import raster2019 from "./raster2019.png"; // Import using relative path
import raster2022 from "./raster2022.png"; // Import using relative path

import ReactMapGL, { GeolocateControl, Source, Layer } from 'react-map-gl';

import {
  state_data,
  state_viewPort,
  updateViewport,
} from './reducer';

// DESCARGAS
import png_mapa2019 from "../../assets/download/mapa2019.png";
import png_mapa2022 from "../../assets/download/mapa2022.png";
import tif_mapa2019 from "../../assets/download/mapa_virch_2019_wgs84.tif";
import tif_mapa2022 from "../../assets/download/mapa_virch_2022_wgs84.tif";
import clr from "../../assets/download/paleta_colores_QGIS_VIRCH.clr";
import kmz_mapa2019 from "../../assets/download/VIRCh_Mapa2019_EcoFluvial.kmz";
import kmz_mapa2022 from "../../assets/download/VIRCh_Mapa2022_EcoFluvial.kmz";


const useStyles = makeStyles((theme) => ({
  greenButton: {
    // color: theme.palette.getContrastText(green[500]),
    backgroundColor: "#86ad3f",
    '&:hover': {
      backgroundColor: "#567026",
    },
  },
  orangeButton: {
    // color: theme.palette.getContrastText(orange[500]),
    backgroundColor: "#e56a54",
    '&:hover': {
      backgroundColor: "#c35e4b",
    },
  },
  blueButton: {
    // color: theme.palette.getContrastText(blue[500]),
    backgroundColor: "#28939c",
    '&:hover': {
      backgroundColor: "#1b6970",
    },
  },
  center: {
    display: "block",
    margin: "auto",
    width: "100%",
  },
  geolocate: {
    position: "absolute",
    zIndex: "1",
    right: "40px",
    top: "10px",
  },
  checkButton: {
    position: "absolute",
    zIndex: "1",
    left: "10px",
    top: "10px",
    padding: "5px",
    marginButton: "5px",
    backgroundColor: "rgba(150, 150, 150, 0.8)",
    color: "#000",
    textDecoration: "none",
  },
}));

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY3JwYWNoZWNvIiwiYSI6ImNremE4aGtqMTF6eDYybnB2N2tzbW9wN2oifQ.MS9gGp7Q6NEjC69-o0PzjA";

function RSTbox(props) {
  return <p>
    <span style={{ backgroundColor: "#" + props.color, width: "10px", padding: "5px", marginRight: "5px" }}> &nbsp;&nbsp;</span>
    <span>{props.texto}</span>
  </p>;
}

const MyCheckBoxList = [
  {
    order: 1,
    name: "2019",
    imagen: raster2019
  },
  {
    order: 2,
    name: "2022",
    imagen: raster2022
  }
];

const CheckBoxRaster = ({ obj, onChange }) => {
  return <>
    <label>
      <input
        style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
        type="checkbox"
        id={`custom-checkbox-${obj.index}`}
        name={obj.name}
        value={obj.value}
        checked={obj.checked}
        onChange={() => onChange({ ...obj, checked: !obj.checked })}
      />
      <span style={{ verticalAlign: "middle", color: "#000" }} >
        {obj.name}
      </span>
    </label>
  </>
};

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectViewPort = useSelector(state_viewPort);

  const [raster, setRaster] = useState(rasterVacio);
  const [data, setData] = useState(
    MyCheckBoxList.sort((a, b) => b.order - a.order)
  );

  const [png, setPNG] = useState();
  const [tif, setTIF] = useState();
  const [kmz, setKMZ] = useState();
  const [rst, setRST] = useState();
  const [disabled, setdisabled] = useState(true);

  let _updateViewport = (viewport) => {
    dispatch(updateViewport(viewport));
  };

  const handleOnChange = (obj) => {
    // console.log("objeto: " +  JSON.stringify(obj));
    obj.value = !obj.value;
    // obj.checked = !obj.checked;

    setRaster(rasterVacio);
    let encontrado = false;

    setTIF(null);
    setRST(null);
    setPNG(null);
    setKMZ(null);
    setdisabled(!encontrado);

    data.forEach((d, index) => {
      if (d.value && !encontrado) {
        setRaster(d.imagen);
        //console.log("V: " + d.name);
        if (d.name === "2019") {
          setTIF(tif_mapa2019);
          setRST(clr);
          setPNG(png_mapa2019);
          setKMZ(kmz_mapa2019);
        } else {
          setTIF(tif_mapa2022);
          setRST(clr);
          setPNG(png_mapa2022);
          setKMZ(kmz_mapa2022);
        }
        encontrado = true;
        setdisabled(!encontrado);
      }
    });
  };

  const imageSource = {
    type: "image",
    url: raster,
    coordinates: [
      [-65.9745, -43.2005],
      [-64.9714, -43.2005],
      [-64.9714, -43.4660],
      [-65.9745, -43.4660],
    ],
  };


  return (
    <div>

      <GridContainer>
        {/* <GridItem xs={12} sm={6} md={12}> */}

        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardHeader color="success">
              <h2>MAPA</h2>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={10}>
                  {/* <PublicMaps titulo={mapa}/> */}
                  <ReactMapGL
                    {...selectViewPort}
                    style={{ position: "relative" }}
                    width="100%"
                    height="calc(80vh)"
                    scrollZoom={true}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    mapStyle={"mapbox://styles/mapbox/satellite-streets-v11?optimize=true"}
                    onViewportChange={_updateViewport}
                  >

                    <div className={classes.checkButton}>
                      {data.map((obj, index) => (
                        <div key={index}>
                          <CheckBoxRaster
                            obj={obj}
                            onChange={() => handleOnChange(obj)}
                          />
                        </div>
                      ))}

                    </div>

                    <Source id="my-image" type="image" {...imageSource} />
                    <Layer
                      id="my-image-layer"
                      type="raster"
                      source="my-image"
                      paint={{ "raster-opacity": 0.5 }}
                    />

                    <div className={classes.geolocate} >
                      <GeolocateControl />
                    </div>
                  </ReactMapGL>
                </GridItem>

                <GridItem xs={12} sm={2}>
                  <Paper style={{ padding: "10px" }}>
                    <RSTbox color="cbdfb7" texto="Pastura degradada" />
                    <RSTbox color="de2c13" texto="Frutales" />
                    <RSTbox color="f131ab" texto="Hortalizas" />
                    <RSTbox color="c0c3be" texto="Arbustal" />
                    <RSTbox color="9c8019" texto="Corrales" />
                    <RSTbox color="cfef4d" texto="Agua" />
                    <RSTbox color="cfef4d" texto="Arboles" />
                    <RSTbox color="252114" texto="Construido" />
                    <RSTbox color="f19e31" texto="invernadero" />
                    <RSTbox color="9c8019" texto="Pastura" />
                  </Paper>
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Paper style={{ padding: "2px" }} >
                    <a href={tif} target="_blank">
                      <Button className={classes.greenButton} disabled={disabled}>
                        TIFF
                      </Button>
                    </a>
                    <p>TIFF: Imagen raster con metada de las clases de uso y cobertura del suelo. Utilizar vinculado al RST.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Paper style={{ padding: "2px" }}>
                    <a href={rst} target="_blank">
                      <Button className={classes.orangeButton} disabled={disabled}>
                        RST
                      </Button>
                    </a>
                    <p>RST: Paleta de colores de las clases en QGIS.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Paper style={{ padding: "2px" }}>
                    <a href={png} target="_blank">
                      <Button className={classes.blueButton} disabled={disabled}>
                        PNG
                      </Button>
                    </a>
                    <p>PNG: Imagen plana.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Paper style={{ padding: "2px" }}>
                    <a href={kmz} target="_blank">
                      <Button className={classes.orangeButton} disabled={disabled}>
                        KMZ
                      </Button>
                    </a>
                    <p>KMZ: Mapa para ser utilizado en Google Earth.</p>
                  </Paper>
                </GridItem>
              </GridContainer>
            </CardFooter>

          </Card>
        </GridItem>
      </GridContainer >


    </div >
  );
}

export default Dashboard;