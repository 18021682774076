import React, { useState } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import { login_api } from "./API";
import { useDispatch } from "react-redux";
import { login } from "./reducer";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import CustomButton from "components/CustomButtons/Button.js";//

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Image from "./background.png"; // Import using relative path

const useStyles = makeStyles((theme) => ({
  successText: {
    color: "#222222",
    fontSize: "20px",
  },
  cardImage: {
    backgroundImage: 'url(require("./background.png"))',
  },
  formInput: {
    backgroundColor: "#e7f0f4",
  },

}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = async () => {
    if (email === "" || password === "") {
      setError("Campos requeridos");
      return;
    }
    login_api({ email, password })
      .then((response) => {
        console.log(response);
        let resp_status = response.status;
        if (resp_status !== 200) {
          return response.json().then((response) => {
            setError(response.message);
          });
        }
        return response
          .json()
          .then((response) => {
            dispatch(login({ resp_status, response }));
          })
          .catch((err) => setError(err.toString()));
      })
      .catch((err) => setError(err.toString()));
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              paddingBottom: "150px",
              paddingTop: "100px",
            }}
          >
            <CardBody
              style={{
                paddingRight: "15%",
                paddingLeft: "15%",
              }}
            >
              <img src={logo} alt="logo" width={50} className="center" />
              <h2 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h2>

              &nbsp;
              <div className="center">
                <div className="">
                  <form>
                    <Typography variant="h5" style={{ marginBottom: 8 }}>
                      Ingresar
                    </Typography>
                    &nbsp;
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    &nbsp;
                    <TextField
                      label="Contraseña"
                      variant="outlined"
                      fullWidth
                      className={classes.formInput}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="form-input"
                      size="large"
                      onClick={submitForm}
                    >
                      Ingresar
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    {error && (
                      <Alert severity="error" onClick={() => setError(null)}>
                        {props.error || error}
                      </Alert>
                    )}
                    &nbsp;
                  </form>

                  <img src={logo} alt="logo" className="logo" />
                  <h4 style={{ textAlignVertical: "center", textAlign: "center", }} >Servicio Para Observadores/as Territoriales</h4>

                  &nbsp;
                  <GridItem xs={12} sm={6} md={12}>
                    <CustomButton onClick={() => history.push("/register")}>Registrate</CustomButton>
                    &nbsp;
                    <CustomButton onClick={() => history.push("/home")}>Inicio</CustomButton>
                    &nbsp;
                    <CustomButton onClick={() => history.push("/recover")}>Recuperar contraseña</CustomButton>
                  </GridItem>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}


Login.propTypes = {
  login: PropTypes.func,
  error: PropTypes.bool,
  setIsAuthenticated: PropTypes.func,
};
