import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signupUser_api } from "./API";

const initialState = {
  isAuthUser: localStorage.getItem("token") ? true : false,
  user: JSON.parse(localStorage.getItem("user")),
  signup_message: { message: null, severity: null },
  login_message: { message: null, severity: null },
  token: localStorage.getItem("token"),
};

export const signupUser = createAsyncThunk(
  "users/signUp",
  async (user, { rejectWithValue }) => {
    const response = await signupUser_api(user);
    if (response.ok) {
      return response.json();
    }
    const data = await response.json();
    return rejectWithValue(data);
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      debugger;
      if (action.payload.resp_status == 200) {
        state.token = action.payload.response.token;
        state.isAuthUser = true;
        state.user = action.payload.response.user;
        localStorage.setItem("token", action.payload.response.token);
        localStorage.setItem(
          "user",
          JSON.stringify(action.payload.response.user)
        );
      } else {
        state.isAuthUser = false;
        state.login_message = {
          message: action.payload.response.message, 
          severity: "error",
        };
      }
    },
    setError: (state, action) => {
      state.signup_message = action.payload;
    },
    clearErrors: (state) => {
      state.signup_message = initialState.signup_message;
      state.login_message = initialState.login_message;
    },
    logout: (state) => {
      state.user = {};
      state.token = null;
      state.isAuthUser = false;
      localStorage.removeItem("token");
    },
    // recoverPassword: (state) => {
    //   state.user = {};
    //   //DAMIAN
    //   console.log("reduce LOGIN")
    //   console.log(state)
    // }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(signupUser.fulfilled, (state, action) => {
      state.signup_message = {
        message: action.payload.message,
        severity: "success",
      };
    }),
      builder.addCase(signupUser.rejected, (state, action) => {
        // Add user to the state array

        let error_message = action.payload.message;
        if (
          action.payload.errObj &&
          action.payload.errObj.errors &&
          action.payload.errObj.errors.length > 0
        ) {
          error_message =
            error_message + " - " + action.payload.errObj.errors[0].message;
        }
        state.signup_message = { message: error_message, severity: "error" };
      });
  },
});

export const recoverPassword = createAsyncThunk(
  "users/recoverpassword",
  async (user, { rejectWithValue }) => {
    const response = await recoverPassword_api(user);
    console.log("LOGIN reduce: " + user.email); // BORRAR
    console.log("LOGIN reduce: " + user.firstname); // BORRAR
    console.log("LOGIN reduce (response): " + response); // BORRAR
    if (response.ok) {
      return response.json();
    }
    const data = await response.json();
    return rejectWithValue(data);
  }
);

export const { logout, login, setError, clearErrors } = loginSlice.actions;

export const selectAuthUser = (state) => state.login.isAuthUser;
export const selectUser = (state) => state.login.user;
export const selectSignUpMessage = (state) => state.login.signup_message;

export default loginSlice.reducer;
