import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import centroid from "@turf/centroid";
import { server } from "../../config";

const initialState = {
  polygons: [],
  data: [],
  viewPort: {
    // Vista del mapa
    longitude: -65.5021865,//-62.228306963714076
    latitude:-43.3108835,//-36.981645119763485
    zoom: 11,  
  },
  selectedIndex: null,
  alert: {
    opened: false,
    text: "",
    type: "warning",
    duration: 6000,
  },
};

export const storeRemotely = createAsyncThunk(
  "map/storeRemotely",
  async (payload, { getState }) => {
    const url = server + "/map/update_story";
    const state = getState();
    var bearer = "Bearer " + state.login.token;
    const response = await fetch(url, {
      headers: { "Content-Type": "application/json", Authorization: bearer },
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        polygons: state.map.polygons,
        data: state.map.data,
        id: state.story.id,
      }),
      // body data type must match "Content-Type" header
    });
    return response;
  }
);

export const mapSlice = createSlice({
  name: "map",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    removePolygon: (state, action) => {
      if (action.payload.id < state.polygons.length) {
        state.polygons = state.polygons.splice(action.payload.id, 1);
        state.data = state.polygons.splice(action.payload.id, 1);
      }
    },
    updateCampaignData: (state, action) => {
      const { index, newData } = action.payload;
      const aux = [...newData];
      state.data[index] = aux;
    },
    updateViewport: (state, action) => {
      state.viewPort = action.payload;
    },
    centerViewPort: (state) => {
      if (state.polygons.length > 0) {
        let center = centroid(state.polygons[0]);
        console.log(center);
        center = center.geometry.coordinates;
        state.viewPort = {
          longitude: center[0],
          latitude: center[1],
          zoom: 14,
        };
      }
    },
    setPolygonsData: (state, actions) => {
      if (actions.payload.polygons) {
        state.polygons = actions.payload.polygons
          ? actions.payload.polygons
          : [];
      }
      if (actions.payload.data) {
        state.data = actions.payload.data ? actions.payload.data : [];
      }
    },
    closeAlert: (state) => {
      state.alert = { ...state.alert, opened: false };
    },
    reset: (state) => initialState,
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setSelectedIndex: (state, action) => {
      if (action.payload < state.polygons.length) {
        state.selectedIndex = action.payload;
      }
    },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder.addCase(storeRemotely.fulfilled, (state, action) => {
      state.alert = {
        opened: true,
        text: "El polígono ha sido actualizado con éxito.",
        type: "success",
        duration: 4000,
      };
    });
  },
});

export const {
  removePolygon,
  updateCampaignData,
  updateViewport,
  centerViewPort,
  setPolygonsData,
  closeAlert,
  setAlert,
  setSelectedIndex,
  reset,
} = mapSlice.actions;

export const state_polygons = (state) => state.map.polygons;
export const state_data = (state) => state.map.data;
export const state_selectedIndex = (state) => state.map.selectedIndex;
export const state_alert = (state) => state.map.alert;
export const state_viewPort = (state) => state.map.viewPort;

export default mapSlice.reducer;
